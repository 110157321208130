import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jr-q-engineer-jd',
  templateUrl: './jr-q-engineer-jd.component.html',
  styleUrls: ['./jr-q-engineer-jd.component.scss']
})
export class JrQEngineerJdComponent implements OnInit {

  selectedJob = 'Jr. QA Engineer';

  skills = [
    "Bachelor’s degree in Computer Science, Engineering, or a related field.",
    "2+ years of experience.",
    "Basic understanding of software testing principles and methodologies.",
    "Familiarity with manual testing tools and bug tracking software (e.g., JIRA, Bugzilla) is a plus.",
    "Exposure to test automation tools (e.g., Selenium) is desirable but not mandatory.",
    "Strong analytical skills and attention to detail.",
    "Excellent communication and teamwork skills.",
    "A proactive attitude towards learning and development."
];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
