import { Component, OnInit } from '@angular/core';
import { ROUTES } from 'src/app/constants/Routes';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  Routes = ROUTES;

  jobs = [
    {
      title: "DevOps Engineer",
      experience: "5-6 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "We are looking for a DevOps Engineer with expertise in cloud solutions, CI/CD pipelines, and monitoring tools to support deployment and infrastructure.",
      route: this.Routes.devops_jd,
      tags: ["5-6 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Java Developer",
      experience: "5-6 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "A seasoned Java Developer is required to design, develop, and maintain high-performance, scalable, and secure Java applications.",
      route: this.Routes.java_jd,
      tags: ["5-6 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Android Developer",
      experience: "5-6 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "We are hiring an Android Developer to create high-quality mobile applications, ensuring performance and responsiveness.",
      route: this.Routes.android_jd,
      tags: ["5-6 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "iOS Developer",
      experience: "5-6 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "Looking for an iOS Developer to build cutting-edge mobile applications tailored for Apple's ecosystem.",
      route: this.Routes.ios_jd,
      tags: ["5-6 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Senior Manager - Digital Marketing & Communication",
      experience: "10+ years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "The Senior Manager - Digital Marketing & Communication will focus on brand positioning, go-to-market strategies, and customer engagement initiatives.",
      route: this.Routes.marcom_jd,
      tags: ["2-3 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Sales & Customer Support Specialist",
      experience: "0-2 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time - 6 Working Days",
      description: "This role involves managing customer inquiries, ensuring client satisfaction, and supporting sales operations.",
      route: this.Routes.customer_support_jd,
      tags: ["0-2 years", "Pune, Maharashtra", "Full Time - 6 Working Days"]
    },
    {
      title: "Jr. QA Engineer",
      experience: "2+ years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "We need a Junior QA Engineer to test, debug, and ensure the quality of software applications while adhering to industry standards.",
      route: this.Routes.jr_qa_jd, // Replace with the actual route if available
      tags: ["2+ years", "Pune, Maharashtra", "Full Time"]
    }
  ];
  
  
  

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

}
